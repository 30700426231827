// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movie-js": () => import("./../src/pages/movie.js" /* webpackChunkName: "component---src-pages-movie-js" */),
  "component---src-pages-movie-list-js": () => import("./../src/pages/movieList.js" /* webpackChunkName: "component---src-pages-movie-list-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-sintyoku-js": () => import("./../src/pages/sintyoku.js" /* webpackChunkName: "component---src-pages-sintyoku-js" */),
  "component---src-pages-utakai-js": () => import("./../src/pages/utakai.js" /* webpackChunkName: "component---src-pages-utakai-js" */)
}

